import AuthResource from '@/resources/AuthResource';
import useApiToken from '@/composables/use-api-token';
import useLoggedInUser from '@/composables/use-logged-in-user';

const { setLoggedInUser, deleteLoggedInUser } = useLoggedInUser();
const { getApiToken, setApiToken, deleteApiToken } = useApiToken();

export interface ILoginForm {
    email: string;
    password: string;
}

export default new (class AuthService {
    private authResource: AuthResource;

    constructor() {
        this.authResource = new AuthResource();
    }

    public isLoggedIn(): boolean {
        return !!getApiToken();
    }

    public async login(form: ILoginForm) {
        return this.authResource.login(form).then((response) => {
            setApiToken(response.data.token);
            setLoggedInUser(response.data.user);

            return Promise.resolve();
        });
    }

    public async logout() {
        return this.authResource.logout().then(() => {
            return Promise.resolve();
        }).finally(() => {
            deleteApiToken();
            deleteLoggedInUser();
        });
    }
})();
