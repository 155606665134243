import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AuthService from '@/services/AuthService';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/auth/login',
        name: 'auth-login',
        meta: { layout: 'UnauthorizedLayout' },
        component: () =>
            import(
                /* webpackChunkName: "auth-login" */ '../views/Page/Auth/Login.vue'
            ),
    },
    {
        path: '/',
        redirect: {
            name: 'catalog-index'
        }
    },
    {
        path: '/catalog',
        name: 'catalog-index',
        component: () =>
            import(
                /* webpackChunkName: "catalog-index" */ '../views/Page/Catalog/Index.vue'
            ),
    },
    {
        path: '/category',
        name: 'category-index',
        component: () =>
            import(
                /* webpackChunkName: "category" */ '../views/Page/Category/Index.vue'
            ),
    },
    {
        path: '/category/create',
        name: 'category-create',
        component: () =>
            import(
                /* webpackChunkName: "category-create" */ '../views/Page/Category/Create.vue'
            ),
    },
    {
        path: '/category/edit/:id',
        name: 'category-edit',
        component: () =>
            import(
                /* webpackChunkName: "category-edit" */ '../views/Page/Category/Edit.vue'
            ),
    },
    {
        path: '/event',
        name: 'event-index',
        component: () =>
            import(
                /* webpackChunkName: "event" */ '../views/Page/Event/Index.vue'
            ),
    },
    {
        path: '/event/create',
        name: 'event-create',
        component: () =>
            import(
                /* webpackChunkName: "event-create" */ '../views/Page/Event/Create.vue'
            ),
    },
    {
        path: '/event/edit/:id',
        name: 'event-edit',
        component: () =>
            import(
                /* webpackChunkName: "event-edit" */ '../views/Page/Event/Edit.vue'
            ),
    },
    {
        path: '/product',
        name: 'product-index',
        component: () =>
            import(
                /* webpackChunkName: "product" */ '../views/Page/Product/Index.vue'
            ),
    },
    {
        path: '/product/create',
        name: 'product-create',
        component: () =>
            import(
                /* webpackChunkName: "product-create" */ '../views/Page/Product/Create.vue'
            ),
    },
    {
        path: '/product/edit/:id',
        name: 'product-edit',
        component: () =>
            import(
                /* webpackChunkName: "product-edit" */ '../views/Page/Product/Edit.vue'
            ),
    },
    {
        path: '/product-reservation',
        name: 'product-reservation-index',
        component: () =>
            import(
                /* webpackChunkName: "product-reservation" */ '../views/Page/ProductReservation/Index.vue'
            ),
    },
    {
        path: '/product-reservation/create',
        name: 'product-reservation-create',
        component: () =>
            import(
                /* webpackChunkName: "product-reservation-create" */ '../views/Page/ProductReservation/Create.vue'
            ),
    },
    {
        path: '/product-reservation/edit/:id',
        name: 'product-reservation-edit',
        component: () =>
            import(
                /* webpackChunkName: "product-reservation-edit" */ '../views/Page/ProductReservation/Edit.vue'
            ),
    },
    {
        path: '/user',
        name: 'user-index',
        component: () =>
            import(
                /* webpackChunkName: "user" */ '../views/Page/User/Index.vue'
            ),
    },
    {
        path: '/user/create',
        name: 'user-create',
        component: () =>
            import(
                /* webpackChunkName: "user-create" */ '../views/Page/User/Create.vue'
            ),
    },
    {
        path: '/user/edit/:id',
        name: 'user-edit',
        component: () =>
            import(
                /* webpackChunkName: "user-edit" */ '../views/Page/User/Edit.vue'
            ),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // if (store.getters.isAuthenticated) {
    //   next()
    //   return
    // }

    if (!AuthService.isLoggedIn() && to.name !== 'auth-login') {
        next('/auth/login');
    } else {
        next();
    }
});

export default router;
