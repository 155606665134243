interface IUserRole {
    id: number;
    name: string;
}
interface ILoggedInUser {
    id?: number;
    name?: string;
    email?: string;
    role?: IUserRole
}

export default function useLoggedInUser() {
    const state = {
        loggedInUser: <ILoggedInUser|null>null
    }

    const getLoggedInUser = () => {
        if (state.loggedInUser) {
            return state.loggedInUser;
        }

        return JSON.parse(
            window.localStorage.getItem('logged-in-user') ?? '{}'
        );
    };

    const setLoggedInUser = (user: ILoggedInUser) => {
        state.loggedInUser = user;

        window.localStorage.setItem('logged-in-user', JSON.stringify(user));
    };

    const deleteLoggedInUser = () => {
        state.loggedInUser = null;

        window.localStorage.removeItem('logged-in-user');
    }

    return {
        getLoggedInUser,
        setLoggedInUser,
        deleteLoggedInUser,
    }
}
