import { reactive, toRefs } from 'vue';

export interface ILocale {
    code: string;
    label: string;
}

export default function useLocale() {
    const currentLocaleCode = (window.localStorage.getItem('locale') ?? process.env.VUE_APP_I18N_LOCALE);
    const state = reactive({
        currentLocale: <ILocale|null>null
    });

    if (!state.currentLocale) {
        state.currentLocale = supportedLocales[currentLocaleCode];
    }

    const setCurrentLocale = (locale: ILocale) => {
        state.currentLocale = locale;

        window.localStorage.setItem('locale', locale.code);
    };

    return {
        ...toRefs(state),
        currentLocaleCode,
        setCurrentLocale,
    };
};

export const supportedLocales: Record<string, ILocale> = {
    et: {
        code: 'et',
        label: 'Eesti',
    },
    en: {
        code: 'en',
        label: 'English',
    },
};
