import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import useApiToken from '@/composables/use-api-token';

const { getApiToken } = useApiToken();

export default function useAxios() {
    const apiClient = (): AxiosInstance => {
        const config = <AxiosRequestConfig>{
            baseURL: process.env.VUE_APP_API_URL,
            withCredentials: true,
        };

        if (getApiToken()) {
            config.headers = {
                Authorization: `Bearer ${getApiToken()}`
            }
        }

        return axios.create(config);
    }

    return {
        apiClient
    }
}
