
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import useLocale, { ILocale, supportedLocales } from '@/composables/use-locale';

export default defineComponent({
    setup() {
        const i18n = useI18n();
        const { currentLocale, setCurrentLocale } = useLocale();

        function setLocale (locale: ILocale) {
            i18n.locale.value = locale.code;
            setCurrentLocale(locale);
        }

        return {
            supportedLocales,
            currentLocale,
            setLocale,
        };
    },
});
