import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "menu p-3" }
const _hoisted_2 = { class: "menu-label" }
const _hoisted_3 = { class: "menu-list" }
const _hoisted_4 = { class: "menu-label" }
const _hoisted_5 = { class: "menu-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('general.GENERAL')), 1),
    _createElementVNode("ul", _hoisted_3, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          to: "/catalog",
          class: "",
          "active-class": "is-active"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.CATALOG')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          to: "/product-reservation",
          class: "",
          "active-class": "is-active"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.PRODUCT_RESERVATION')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.loggedInUser?.role?.id === _ctx.UserRoleEnum.ROLE_ADMIN)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('general.ADMINISTRATION')), 1),
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/category",
                class: "",
                "active-class": "is-active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('category.CATEGORIES')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/event",
                class: "",
                "active-class": "is-active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('event.EVENTS')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/product",
                class: "",
                "active-class": "is-active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('product.PRODUCTS')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/user",
                class: "",
                "active-class": "is-active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('user.USERS')), 1)
                ]),
                _: 1
              })
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}