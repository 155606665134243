
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from '@/views/Layout/DefaultLayout.vue';
import UnauthorizedLayout from '@/views/Layout/UnauthorizedLayout.vue';

export default defineComponent({
    components: { UnauthorizedLayout, DefaultLayout },
    setup() {
        const route = useRoute();
        const defaultLayout = 'DefaultLayout';

        const layout = computed(() => {
            return route.meta.layout || defaultLayout;
        });

        return {
            layout,
            defaultLayout,
        };
    },
});
