
import { defineComponent } from 'vue';
import Sidebar from '@/components/Sidebar.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import AuthService from '@/services/AuthService';
import { useRouter } from 'vue-router';

export default defineComponent({
    components: { LanguageSwitcher, Sidebar },

    setup() {
        const router = useRouter();

        async function logout() {
            AuthService.logout().then(() => {
                router.push({
                    name: 'auth-login',
                });
            });
        }

        return {
            logout,
        };
    },
});
