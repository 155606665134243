import { AxiosResponse } from 'axios';
import { ILoginForm } from '@/services/AuthService';
import useAxios from '@/composables/use-axios';

const { apiClient } = useAxios();

export default class AuthResource {
    public async login(form: ILoginForm): Promise<AxiosResponse> {
        return apiClient().get('/sanctum/csrf-cookie').then(() => {
            return apiClient().post('/auth/login', form);
        });
    }

    public async logout(): Promise<AxiosResponse> {
        return apiClient().post('/auth/logout');
    }
}
