export default function useApiToken() {
    const state = {
        apiToken: <string|null>null
    };

    const getApiToken = () => {
        if (state.apiToken) {
            return state.apiToken;
        }

        return window.localStorage.getItem('api-token');
    };

    const setApiToken = (token: string) => {
        state.apiToken = token;

        window.localStorage.setItem('api-token', token);
    };

    const deleteApiToken = () => {
        state.apiToken = null;

        window.localStorage.removeItem('api-token');
    }

    return {
        getApiToken,
        setApiToken,
        deleteApiToken,
    }
}
