import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import i18n from './i18n';

import '@fortawesome/fontawesome-free/js/all.js';

import './assets/sass/vendor.scss';
import './assets/sass/app.scss';

createApp(App).use(i18n).use(router).mount('#app');
