
import { defineComponent } from 'vue';
import useLoggedInUser from '@/composables/use-logged-in-user';
import { UserRoleEnum } from '@/enums/UserRoleEnum';

export default defineComponent({
    setup() {
        const { getLoggedInUser } = useLoggedInUser();

        return {
            loggedInUser: getLoggedInUser(),
            UserRoleEnum
        }
    }
});
